<template>
  <ul class="desktop-navigation">
    <router-link to="/"><li> Начало</li></router-link>
    <router-link to="/for-sale"><li>Продажба</li></router-link>
    <router-link to="/for-rent"><li>Наем</li></router-link>
    <router-link to="/about-us"><li>За нас</li></router-link>
  </ul>
</template>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";
a.router-link-active {
  color: $color-white;
  border-bottom: 1px solid white;
  @media only screen and (max-width: 1024px) {
          color: $color-black;
          border-bottom: 1px solid black;
        }

}
</style>
