<template>
  <!-- Show/hide drawer button -->
  <div class="advanced-search-toggler-wrapper">
    <div class="properties-result-count">
      Открити са <span style="color: #a90831"> {{ totalItems }} </span> имота в категорията
    </div>
    <div class="advanced-search-toggler" @click="showDrawer">
      <el-icon :size="25"><Operation /></el-icon>
      <span class="u-center-text">търсене</span>
    </div>
    <div>
      <!-- Element plus library drawer  -->
      <el-drawer
        v-model="drawer"
        title="I am the title"
        :with-header="false"
        direction="ltr"
        size="500px"
        handleClose
      >
        <div>
          <advanced-search
            :hideDrawer="hideDrawer"
            :data="data"
          ></advanced-search>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import { usePropertiesStore } from "@/stores/PropertiesStore";
import AdvancedSearch from "./AdvancedSearch.vue";
export default {
  components: {
    AdvancedSearch,
  },
  props: ["data"],
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    showDrawer() {
      this.drawer = true;
    },
    hideDrawer() {
      this.drawer = false;
    },
  },
  computed: {
    totalItems() {
      const propertiesStore = usePropertiesStore();
      return propertiesStore.totalItems;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/main.scss";
</style>
